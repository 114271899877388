import produce from 'immer';

export const actions = {
  INIT: 'INIT',
  FILTER: 'FILTER',
  SEARCH: 'SEARCH',
};

//const oneDay = 1000 * 60 * 60 * 24;

const handoverReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case actions.INIT: {
        // data = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        // const startDate = new Date(data[0].createdAt);
        // const endDate = new Date(startDate.getTime() - 7 * oneDay);
        return {
          ...data,
          actualSessionList: data,
          filteredSessionList: data,
        };
      }

      case actions.FILTER: {
        // const { startDate, endDate } = data.data;
        // draft.filteredSessionList = state?.actualSessionList?.filter(
        //   (session) =>
        //     new Date(session.createdAt).getTime() >= startDate.getTime() - oneDay &&
        //     new Date(session.createdAt).getTime() <= endDate.getTime() + oneDay
        // );
        break;
      }

      case actions.SEARCH: {
        const searchedList =
          data === ''
            ? state.actualSessionList
            : state.actualSessionList.filter((item) => item.code.toLowerCase().includes(data));
        draft.filteredSessionList = searchedList;
        break;
      }

      default:
        return state;
    }
  });
};

export default handoverReducer;

export const filterOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'ONGOING', label: 'Ongoing' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' },
];
