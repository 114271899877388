import React, { useState, useReducer } from 'react';
import { useMutation, useQuery } from 'react-query';
import fetch from 'utils/fetch';
import handoverReducer, { actions } from './handoverReducer';
import { useBranchId } from 'utils/customHooks';
import HandoverSessionList from './HandoverSessionList';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Download as DownloadIcon } from 'components/Icons';
import Uploader, { DefaultUploaderRender } from 'components/Uploader';
import { saveAs } from 'file-saver';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'utils/customHooks';
import { getDateRange } from 'utils/utils';

const initialDates = getDateRange(new Date(), 7);

export default function Handover() {
  const branchId = useBranchId();
  const [startDate, setStartDate] = useState(initialDates[0]);
  const [endDate, setEndDate] = useState(initialDates[1]);
  const [state, dispatch] = useReducer(handoverReducer, {});
  const [open, setOpen] = useState(false);
  const [notif] = useSnackbar();

  const { refetch: refetchHandoverSession, isFetching: isFetchingHandover } = useQuery(
    branchId && startDate && endDate && ['handoverList', { branchId, startDate, endDate }],
    () =>
      fetch({
        url: 'wms/handover/session',
        method: 'GET',
        headers: {
          'x-wh-id': branchId,
        },
        params: {
          createdAtWindowStart: startDate,
          createdAtWindowEnd: endDate,
        },
      }),
    {
      onSuccess: (data) => {
        dispatch([actions.INIT, data]);
      },
    }
  );

  const [uploadFile] = useMutation(
    ({ url, fileData }) =>
      fetch({
        url,
        method: 'POST',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        headers: {
          'Content-Type': 'application/json, blob',
          'x-wh-id': branchId,
        },
        data: fileData,
      }),
    {
      onSuccess: () => {
        notif('Uploaded successfully', { variant: 'success', autoHideDuration: 1000 });
        refetchHandoverSession();
      },
    }
  );

  function prepareAndUploadFile(url, file) {
    const data = new FormData();
    data.append('file', file);
    uploadFile({ url: url, fileData: data });
  }

  const [downloadSample] = useMutation(
    ({ whId }) =>
      fetch({
        url: 'wms/handover/session/upload_sample',
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json, blob',
        },
        params: {
          whId: whId,
        },
      }),
    {
      onSuccess: (data) => {
        saveAs(data, `Sample.xlsx`);
      },
    }
  );

  const [deleteHandoverById] = useMutation(
    ({ whId, sessionId }) =>
      fetch({
        url: `wms/handover/session/${sessionId}`,
        method: 'DELETE',
        params: {
          whId: whId,
          //sessionId: sessionId,
        },
      }),
    {
      onSuccess: () => {
        refetchHandoverSession();
      },
    }
  );

  const options = [
    { icon: <DownloadIcon />, name: 'Sample', id: 'downloadSample' },
    {
      icon: (
        <Uploader
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          url={`/wms/handover/session/upload?whId=${branchId}`}
          onSuccess={() => {
            refetchHandoverSession();
          }}
          //onError={() => notif('Oh Snap! Upload Failed', { variant: 'error' })}
          render={(progress, apiInProgress) => (
            <DefaultUploaderRender
              tooltip="Upload Session"
              progress={progress}
              apiInProgress={apiInProgress}
            />
          )}
        />
      ),
      name: 'Upload',
      id: 'upload',
    },
  ];

  function onDateFilterChanged(dates) {
    if (dates.length) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(initialDates[0]);
      setEndDate(initialDates[1]);
    }
  }

  return (
    <div className="flex bg-background h-full">
      {isFetchingHandover ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      {/* Session list section */}
      <div className="flex flex-grow flex-col w-full">
        <HandoverSessionList
          sessionList={state?.filteredSessionList}
          onFiltersChange={onDateFilterChanged}
          onFilterCancelled={() => onDateFilterChanged([])}
          onFileFetched={(id, file) => {
            prepareAndUploadFile(`/wms/handover/session/${id}/append`, file);
          }}
          deleteHandover={(id) => {
            deleteHandoverById({ whId: branchId, sessionId: id });
          }}
        />
      </div>

      <div className="fixed bottom-5 right-5">
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          //className={classes.speedDial}
          classes={{ root: 'absolute right-3 bottom-3' }}
          icon={<SpeedDialIcon />}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          {options.map((option) => (
            <SpeedDialAction
              key={option.name}
              icon={option.icon}
              tooltipTitle={option.name}
              tooltipOpen
              onClick={() => {
                if (option?.id === 'downloadSample') {
                  downloadSample({ whId: branchId });
                }
                setOpen(false);
              }}
            />
          ))}
        </SpeedDial>
      </div>
    </div>
  );
}
