import React, { useState, useRef, useMemo } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { formatDateForSession } from 'utils/utils';
import Button from '@material-ui/core/Button';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Link from 'components/Link';
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Pagination from '@material-ui/lab/Pagination';
import WmsFilters from './../WmsFilters';

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HandoverSessionList(props) {
  const { sessionList, onFileFetched, deleteHandover, onFiltersChange, onFilterCancelled } = props;
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const currentDeleteId = useRef();

  const [activePage, setActivePage] = useState(1);
  const offset = useMemo(() => (activePage - 1) * 12, [activePage]);

  const handlePageChange = (e, value) => {
    setActivePage(value);
  };

  return (
    <div className="flex flex-col flex-grow w-full h-full">
      {/* Header Section containing Filters */}
      <div className="flex flex-row m-5 justify-between items-center ">
        <div>
          <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
            Handover Sessions
          </p>
        </div>
        <WmsFilters
          onFiltersChange={(selectedDates) => {
            onFiltersChange(selectedDates);
          }}
          onFilterCancelled={onFilterCancelled}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4">
        {sessionList?.slice(offset, offset + 12)?.map((item) => {
          return (
            <SessionItem
              key={item?.id}
              session={item}
              onFileFetched={(id, file) => onFileFetched(id, file)}
              deleteHandover={(id) => {
                currentDeleteId.current = id;
                setDeleteDialogVisibility(true);
              }}
            />
          );
        })}
      </div>
      <div className="mt-5 m-auto">
        {sessionList?.length / 12 > 1 && (
          <Pagination onChange={handlePageChange} count={Math.ceil(sessionList?.length / 12)} />
        )}
      </div>
      <Dialog
        open={deleteDialogVisibility}
        TransitionComponent={transition}
        keepMounted
        onClose={() => setDeleteDialogVisibility(false)}
        aria-labelledby="delete-dialog-slide-title"
        aria-describedby="delete-dialog-slide-description"
      >
        <DialogTitle id="delete-dialog-slide-title">{'Please confirm your action'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-slide-description">
            Your are attempting to delete the handover session. Are you sure about your choice ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogVisibility(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              deleteHandover(currentDeleteId.current);
              setDeleteDialogVisibility(false);
            }}
            color="primary"
          >
            Yes, Delete it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function SessionItem(props) {
  const { session, onFileFetched, deleteHandover } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const inputFile = useRef(null);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white m-5 p-3">
      <div className="flex flex-row">
        <div className="flex-1 flex-col flex-grow">
          <Link to={`/wms/handoverForceComplete/${session.id}/${session.code}`}>
            <div>
              <p className="text-base md:text-base font-bold md:font-bold">{session?.code}</p>
              <div className="flex flex-col">
                <p className="text-xs md:text-xs font-medium md:font-medium">
                  {formatDateForSession(new Date(session?.createdAt))}
                </p>
                <p className="text-xs md:text-xs font-medium md:font-medium">{session?.state}</p>
                {/* {session?.active ? (
              <p className="text-xs md:text-xs font-medium md:font-medium text-green">Active</p>
            ) : (
              <p className="text-xs md:text-xs font-medium md:font-medium text-warn">Inactive</p>
            )} */}
              </div>
            </div>
          </Link>
        </div>
        <div className="">
          <div className="float-right">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem>
                <div className="flex flex-row" onClick={() => inputFile.current.click()}>
                  <AddRoundedIcon color="primary" />
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      //onUploadClicked(item?.uploadUrl, e.target.files[0]);
                      onFileFetched(session?.id, e.target.files[0]);
                      setAnchorEl(null);
                    }}
                  />
                  <p className="font-medium text-xs ml-2">APPEND FILE</p>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  deleteHandover(session?.id);
                  setAnchorEl(null);
                }}
              >
                <Button color="primary" startIcon={<DeleteRoundedIcon color="primary" />}>
                  <p className="font-medium text-xs">Delete handover</p>
                </Button>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <div className="flex flex-row py-2">
        <div className="flex flex-col flex-grow w-20 h-12 rounded md:rounded-lg shadow md:shadow-lg bg-white justify-center items-center mx-1">
          <p>{`${session?.progress?.done}/${session?.progress?.target}`}</p>
          <p>Batches Completed</p>
        </div>
      </div>
    </div>
  );
}
